import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';

import Button from '../button/button';
import ChoosePerformance from '../choose-performance/choose-performance';
import EventDate from '../event-date/event-date';
import EventMeta from '../event-meta/event-meta';
import Sidebar from '../sidebar/sidebar';
import WYSIWYG from '../wysiwyg/wysiwyg';

import styles from './content-display.module.scss';

const ContentDisplay = ({
  title,
  longTitle,
  body,
  cost,
  date,
  html,
  imageLocation = 'sidebar',
  relationships,
  sidebarContent,
  subscription,
  tickets,
  field_image,
  feedMeta,
  fullWidthLayout,
  children,
  fields,
  spektrixClientName
}) => {
  let image = '';
  if (
    relationships &&
    relationships.field_image &&
    relationships.field_image.max_1600_10_7 &&
    relationships.field_image.max_1600_16_9
  ) {
    image = {
      resolutions:
        relationships.field_image.max_1600_10_7.childImageSharp.resolutions,
      sizes: relationships.field_image.max_1600_16_9.childImageSharp.sizes
    };
  }

  // Account for Spektrix imported events.
  const spektrix = fields && fields.spectrix;
  const spektrixId = feedMeta && (feedMeta.guid || feedMeta[0].guid);
  const isMultiple = fields && fields.spectrix && fields.spectrix.numberInstances;

  // If there's a body summary we need some <p> tags around it for spacing.
  const summary = body.summary && `<p>${body.summary}</p>`;

  return (
    <React.Fragment>
      {image && (
        <Img
          className={styles.topImg}
          sizes={image.sizes}
          // These images only show on mobile
          style={{ display: 'none' }}
          alt={field_image && field_image.alt}
        />
      )}
      <article className={styles.article} aria-label={title}>
        <div className={fullWidthLayout ? `${styles.contentWrapper} ${styles.contentWrapperFull}` : styles.contentWrapper }>
          <h1 className={styles.title}>{longTitle || title}</h1>
          {date && <EventDate date={date} />}
          <WYSIWYG>
            {/* Some content will only have a summary. If there's no body fall back to summary. */}
            <div
              className={styles.body}
              dangerouslySetInnerHTML={{ __html: body.processed || summary || body }}
            />
          </WYSIWYG>
          {relationships && (relationships.venue || relationships.series) && (
            <EventMeta
              className={styles.meta}
              venue={relationships.venue && relationships.venue.name}
              series={relationships.series && relationships.series.name}
              cost={cost}
            />
          )}

          {/*
            If this is a Spektrix event AND there are multiple performances,
            render the choose performance component.
            TODO: account for single events.
           */}
          {(spektrixId) && (
            <ChoosePerformance
              spektrixClientName={spektrixClientName}
              spektrix={spektrix}
              tickets={tickets}
              instanceID={spektrixId}
              subscription={subscription}
            />
          )}
          {/*
            TODO: Prob need a better alternative to isSpektrix as
            we still need to support single Spektrix instances as well as multiple.
           */}
          {!isMultiple && (tickets || subscription) && (
            <Buttons tickets={tickets} subscription={subscription} />
          )}
          {imageLocation === 'below' && image && (
            <Img
              className={styles.bottomImg}
              sizes={image.sizes}
              // These images only show on mobile
              style={{ display: 'none' }}
              alt={field_image && field_image.alt}
            />
          )}
          {html && (
            <div
              className={styles.iframeContainer}
              dangerouslySetInnerHTML={{ __html: html.value }}
            />
          )}
          {children}
        </div>
        {(imageLocation === 'sidebar' || sidebarContent) && (
          <Sidebar
            image={imageLocation === 'sidebar' ? image.resolutions : null}
            imageAlt={
              imageLocation === 'sidebar' &&
              field_image &&
              field_image.alt
            }
            content={sidebarContent}
          />
        )}
      </article>
    </React.Fragment>
  );
};

ContentDisplay.propTypes = {
  body: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  cost: PropTypes.string,
  date: PropTypes.object,
  html: PropTypes.object,
  imageLocation: PropTypes.oneOf(['sidebar', 'below', 'none']),
  longTitle: PropTypes.string,
  relationships: PropTypes.object,
  series: PropTypes.string,
  sidebarContent: PropTypes.node,
  subscription: PropTypes.object,
  tickets: PropTypes.object,
  title: PropTypes.string.isRequired,
  venue: PropTypes.string,
  feedMeta: PropTypes.object,
  fullWidthLayout: PropTypes.bool,
  fields: PropTypes.object,
  spektrixClientName: PropTypes.string,
};

export default ContentDisplay;

// Support custom text for buttons with a fallback.
const Buttons = ({ tickets, subscription }) => (
  <div className={styles.buttons}>
    {tickets && (
      <Button
        className={styles.ticketButton}
        to={tickets && tickets.uri}
        textStyle="upper"
      >
        {tickets && tickets.title || 'Find Tickets'}
      </Button>
    )}
    {subscription && (
      <Button
        className={styles.subButton}
        to={subscription && subscription.uri}
        textStyle="upper"
        color="blueMedium"
      >
        {subscription && subscription.title || 'Subscription Packages'}
      </Button>
    )}
  </div>
);

Buttons.propTypes = {
  subscription: PropTypes.object,
  tickets: PropTypes.object,
};
